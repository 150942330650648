// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as RelayAppSync__AWSDateTime from "../../relay-appsync/src/RelayAppSync__AWSDateTime.bs.js";

var Types = {};

function unwrap_response_acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["OrganizationMemberActiveStatus"]);
}

var variablesConverter = {};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"acceptOrganizationMemberHumanInvitation_organization_logo":{"f":""},"acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status_OrganizationMemberActiveStatus_activatedAt":{"c":"RelayAppSync.AWSDateTime"},"acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status":{"u":"response_acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status"},"acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_id":{"c":"DecidrNodeId.RelayAppSync"},"acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_avatar":{"f":""},"acceptOrganizationMemberHumanInvitation_organization_id":{"c":"DecidrNodeId.RelayAppSync"}}};

var wrapResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize,
  "RelayAppSync.AWSDateTime": RelayAppSync__AWSDateTime.serialize,
  response_acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status: RescriptRelay_Internal.wrapUnion
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"acceptOrganizationMemberHumanInvitation_organization_logo":{"f":""},"acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status_OrganizationMemberActiveStatus_activatedAt":{"c":"RelayAppSync.AWSDateTime"},"acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status":{"u":"response_acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status"},"acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_id":{"c":"DecidrNodeId.RelayAppSync"},"acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_avatar":{"f":""},"acceptOrganizationMemberHumanInvitation_organization_id":{"c":"DecidrNodeId.RelayAppSync"}}};

var responseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  "RelayAppSync.AWSDateTime": RelayAppSync__AWSDateTime.parse,
  response_acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status: unwrap_response_acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function organizationMemberType_decode($$enum) {
  if ($$enum === "AI" || $$enum === "Human") {
    return $$enum;
  }
  
}

function organizationMemberType_fromString(str) {
  return organizationMemberType_decode(str);
}

function organizationRole_decode($$enum) {
  if ($$enum === "Member" || $$enum === "Guest" || $$enum === "Owner") {
    return $$enum;
  }
  
}

function organizationRole_fromString(str) {
  return organizationRole_decode(str);
}

var Utils = {
  organizationMemberType_decode: organizationMemberType_decode,
  organizationMemberType_fromString: organizationMemberType_fromString,
  organizationRole_decode: organizationRole_decode,
  organizationRole_fromString: organizationRole_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "invitationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "relayConnectionsToUpdate"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "invitationId",
        "variableName": "invitationId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "alt",
    "storageKey": null
  },
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Size",
    "kind": "LinkedField",
    "name": "size",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "SpacesImageResource_imageResource",
    "selections": (v4/*: any*/),
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surname",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memberType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activatedAt",
          "storageKey": null
        }
      ],
      "type": "OrganizationMemberActiveStatus",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptOrganizationMemberHumanInvitationPayload",
        "kind": "LinkedField",
        "name": "acceptOrganizationMemberHumanInvitation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageResource",
                "kind": "LinkedField",
                "name": "logo",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LoggedInOrganizationMember",
                "kind": "LinkedField",
                "name": "loggedInOrganizationMember",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMember",
                    "kind": "LinkedField",
                    "name": "organizationMember",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImageResource",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptOrganizationMemberHumanInvitationPayload",
        "kind": "LinkedField",
        "name": "acceptOrganizationMemberHumanInvitation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageResource",
                "kind": "LinkedField",
                "name": "logo",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LoggedInOrganizationMember",
                "kind": "LinkedField",
                "name": "loggedInOrganizationMember",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMember",
                    "kind": "LinkedField",
                    "name": "organizationMember",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImageResource",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "organization",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "relayConnectionsToUpdate"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "OrganizationEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2003a348e7496771ff9ab9a5254d47a2",
    "id": null,
    "metadata": {},
    "name": "SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation(\n  $invitationId: String!\n) {\n  acceptOrganizationMemberHumanInvitation(input: {invitationId: $invitationId}) {\n    organization {\n      id\n      name\n      logo {\n        ...SpacesImageResource_imageResource\n      }\n      loggedInOrganizationMember {\n        organizationMember {\n          id\n          name\n          surname\n          email\n          role\n          memberType\n          avatar {\n            ...SpacesImageResource_imageResource\n          }\n          status {\n            __typename\n            ... on OrganizationMemberActiveStatus {\n              activatedAt\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment SpacesImageResource_imageResource on ImageResource {\n  url\n  alt\n  name\n  size {\n    width\n    height\n  }\n}\n"
  }
};
})());

var wrap_response_acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_response_acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status ,
  wrap_response_acceptOrganizationMemberHumanInvitation_organization_loggedInOrganizationMember_organizationMember_status ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
