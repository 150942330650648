// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as RelayAppSync__AWSDateTime from "../../relay-appsync/src/RelayAppSync__AWSDateTime.bs.js";

var Types = {};

function unwrap_response_revokeOrganizationMemberHumanInvitation_human_status(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["OrganizationMemberInvitationRevokedStatus"]);
}

var variablesConverter = {"__root":{"organizationMemberId":{"c":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"revokeOrganizationMemberHumanInvitation_revokedOrganizationMemberId":{"c":"DecidrNodeId.RelayAppSync"},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_revokedBy_id":{"c":"DecidrNodeId.RelayAppSync"},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_revokedBy_avatar":{"f":""},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_revokedAt":{"c":"RelayAppSync.AWSDateTime"},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_invitedBy_id":{"c":"DecidrNodeId.RelayAppSync"},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_invitedBy_avatar":{"f":""},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_invitedAt":{"c":"RelayAppSync.AWSDateTime"},"revokeOrganizationMemberHumanInvitation_human_status":{"u":"response_revokeOrganizationMemberHumanInvitation_human_status"},"revokeOrganizationMemberHumanInvitation_human_id":{"c":"DecidrNodeId.RelayAppSync"},"revokeOrganizationMemberHumanInvitation_human_avatar":{"f":""}}};

var wrapResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize,
  "RelayAppSync.AWSDateTime": RelayAppSync__AWSDateTime.serialize,
  response_revokeOrganizationMemberHumanInvitation_human_status: RescriptRelay_Internal.wrapUnion
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"revokeOrganizationMemberHumanInvitation_revokedOrganizationMemberId":{"c":"DecidrNodeId.RelayAppSync"},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_revokedBy_id":{"c":"DecidrNodeId.RelayAppSync"},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_revokedBy_avatar":{"f":""},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_revokedAt":{"c":"RelayAppSync.AWSDateTime"},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_invitedBy_id":{"c":"DecidrNodeId.RelayAppSync"},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_invitedBy_avatar":{"f":""},"revokeOrganizationMemberHumanInvitation_human_status_OrganizationMemberInvitationRevokedStatus_invitedAt":{"c":"RelayAppSync.AWSDateTime"},"revokeOrganizationMemberHumanInvitation_human_status":{"u":"response_revokeOrganizationMemberHumanInvitation_human_status"},"revokeOrganizationMemberHumanInvitation_human_id":{"c":"DecidrNodeId.RelayAppSync"},"revokeOrganizationMemberHumanInvitation_human_avatar":{"f":""}}};

var responseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  "RelayAppSync.AWSDateTime": RelayAppSync__AWSDateTime.parse,
  response_revokeOrganizationMemberHumanInvitation_human_status: unwrap_response_revokeOrganizationMemberHumanInvitation_human_status
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function organizationMemberType_decode($$enum) {
  if ($$enum === "AI" || $$enum === "Human") {
    return $$enum;
  }
  
}

function organizationMemberType_fromString(str) {
  return organizationMemberType_decode(str);
}

function organizationRole_decode($$enum) {
  if ($$enum === "Member" || $$enum === "Guest" || $$enum === "Owner") {
    return $$enum;
  }
  
}

function organizationRole_fromString(str) {
  return organizationRole_decode(str);
}

var Utils = {
  organizationMemberType_decode: organizationMemberType_decode,
  organizationMemberType_fromString: organizationMemberType_fromString,
  organizationRole_decode: organizationRole_decode,
  organizationRole_fromString: organizationRole_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationMemberId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "organizationMemberId",
        "variableName": "organizationMemberId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revokedOrganizationMemberId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memberType",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "alt",
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Size",
    "kind": "LinkedField",
    "name": "size",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ImageResource",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "SpacesImageResource_imageResource",
      "selections": (v9/*: any*/),
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invitedAt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reason",
  "storageKey": null
},
v14 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v10/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revokedAt",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ImageResource",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v17 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v16/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RevokeOrganizationMemberHumanInvitationPayload",
        "kind": "LinkedField",
        "name": "revokeOrganizationMemberHumanInvitation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMember",
            "kind": "LinkedField",
            "name": "human",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMember",
                        "kind": "LinkedField",
                        "name": "invitedBy",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMember",
                        "kind": "LinkedField",
                        "name": "revokedBy",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "OrganizationMemberInvitationRevokedStatus",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RevokeOrganizationMemberHumanInvitationPayload",
        "kind": "LinkedField",
        "name": "revokeOrganizationMemberHumanInvitation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMember",
            "kind": "LinkedField",
            "name": "human",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMember",
                        "kind": "LinkedField",
                        "name": "invitedBy",
                        "plural": false,
                        "selections": (v17/*: any*/),
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMember",
                        "kind": "LinkedField",
                        "name": "revokedBy",
                        "plural": false,
                        "selections": (v17/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "OrganizationMemberInvitationRevokedStatus",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5980072fa0465708c5d01e60abc9db74",
    "id": null,
    "metadata": {},
    "name": "SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation(\n  $organizationMemberId: ID!\n) {\n  revokeOrganizationMemberHumanInvitation(input: {organizationMemberId: $organizationMemberId}) {\n    revokedOrganizationMemberId\n    human {\n      id\n      name\n      surname\n      email\n      role\n      memberType\n      avatar {\n        ...SpacesImageResource_imageResource\n      }\n      status {\n        __typename\n        ... on OrganizationMemberInvitationRevokedStatus {\n          invitedAt\n          reason\n          invitedBy {\n            id\n            name\n            surname\n            email\n            role\n            avatar {\n              ...SpacesImageResource_imageResource\n            }\n          }\n          revokedAt\n          revokedBy {\n            id\n            name\n            surname\n            email\n            role\n            avatar {\n              ...SpacesImageResource_imageResource\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment SpacesImageResource_imageResource on ImageResource {\n  url\n  alt\n  name\n  size {\n    width\n    height\n  }\n}\n"
  }
};
})());

var wrap_response_revokeOrganizationMemberHumanInvitation_human_status = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_response_revokeOrganizationMemberHumanInvitation_human_status ,
  wrap_response_revokeOrganizationMemberHumanInvitation_human_status ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
