// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils__Email from "../../../utils/src/email/Utils__Email.bs.js";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";
import * as SpacesInvitationMutations_inviteOrganizationMemberHumanMutation_graphql from "../../../relay-codegen/generated/SpacesInvitationMutations_inviteOrganizationMemberHumanMutation_graphql.bs.js";
import * as SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation_graphql from "../../../relay-codegen/generated/SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation_graphql.bs.js";
import * as SpacesInvitationMutations_resendOrganizationMemberHumanInvitationMutation_graphql from "../../../relay-codegen/generated/SpacesInvitationMutations_resendOrganizationMemberHumanInvitationMutation_graphql.bs.js";
import * as SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation_graphql from "../../../relay-codegen/generated/SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation_graphql.bs.js";

var SharedPayload = {};

var convertVariables = SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation_graphql.Internal.convertVariables;

var convertResponse = SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables, SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SpacesInvitationMutations_acceptOrganizationMemberHumanInvitationMutation_graphql.node, convertResponse, convertWrapRawResponse);

function makeAcceptInvitationPayload(response) {
  var organization = response.acceptOrganizationMemberHumanInvitation.organization;
  var organizationMember = organization.loggedInOrganizationMember.organizationMember;
  return {
          organization: {
            id: organization.id,
            dataId: organization.__id,
            name: organization.name
          },
          organizationMember: {
            id: organizationMember.id,
            name: organizationMember.name,
            surname: organizationMember.surname,
            email: organizationMember.email
          }
        };
}

function useAcceptInvitation() {
  var match = use();
  var accept = match[0];
  var handleAccept = function (invitationId, onError, onSuccess, onCompleted, updater, relayConnectionsToUpdateOpt) {
    var relayConnectionsToUpdate = relayConnectionsToUpdateOpt !== undefined ? relayConnectionsToUpdateOpt : [];
    accept({
          invitationId: invitationId,
          relayConnectionsToUpdate: relayConnectionsToUpdate
        }, undefined, undefined, (function (store, response) {
            var payload = makeAcceptInvitationPayload(response);
            Core__Option.forEach(updater, (function (updater) {
                    updater(store, payload);
                  }));
          }), (function (response, errors) {
            var payload = makeAcceptInvitationPayload(response);
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error accepting invitation", "Spaces__Invitation__Mutations handleAccept", onError, (function () {
                    Core__Option.forEach(onSuccess, (function (onSuccess) {
                            onSuccess(payload);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(payload);
                          }));
                  }));
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error accepting invitation", "Spaces__Invitation__Mutations handleAccept", onError);
          }), undefined);
  };
  return [
          handleAccept,
          match[1]
        ];
}

var convertVariables$1 = SpacesInvitationMutations_resendOrganizationMemberHumanInvitationMutation_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesInvitationMutations_resendOrganizationMemberHumanInvitationMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = SpacesInvitationMutations_resendOrganizationMemberHumanInvitationMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$1, SpacesInvitationMutations_resendOrganizationMemberHumanInvitationMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, SpacesInvitationMutations_resendOrganizationMemberHumanInvitationMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

function makeResendInvitationPayload(response) {
  var organizationMember = response.resendOrganizationMemberHumanInvitation.human;
  return {
          organizationMember: {
            id: organizationMember.id,
            name: organizationMember.name,
            surname: organizationMember.surname,
            email: organizationMember.email
          }
        };
}

function useResendInvitation() {
  var match = use$1();
  var resend = match[0];
  var handleResend = function (organizationMemberId, onError, onSuccess, onCompleted, updater) {
    resend({
          organizationMemberId: organizationMemberId
        }, undefined, undefined, (function (store, response) {
            var payload = makeResendInvitationPayload(response);
            Core__Option.forEach(updater, (function (updater) {
                    updater(store, payload);
                  }));
          }), (function (response, errors) {
            var payload = makeResendInvitationPayload(response);
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error resending invitation", "Spaces__Invitation__Mutations handleResend", onError, (function () {
                    Core__Option.forEach(onSuccess, (function (onSuccess) {
                            onSuccess(payload);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(payload);
                          }));
                  }));
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error resending invitation", "Spaces__Invitation__Mutations handleResend", onError);
          }), undefined);
  };
  return [
          handleResend,
          match[1]
        ];
}

var convertVariables$2 = SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation_graphql.Internal.convertVariables;

var convertResponse$2 = SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$2 = SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$2, SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

var use$2 = RescriptRelay_Mutation.useMutation(convertVariables$2, SpacesInvitationMutations_revokeOrganizationMemberHumanInvitationMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

function makeRevokeInvitationPayload(response) {
  var organizationMember = response.revokeOrganizationMemberHumanInvitation.human;
  return {
          organizationMember: {
            id: organizationMember.id,
            name: organizationMember.name,
            surname: organizationMember.surname,
            email: organizationMember.email
          }
        };
}

function useRevokeInvitation() {
  var match = use$2();
  var revoke = match[0];
  var handleRevoke = function (organizationMemberId, onError, onSuccess, onCompleted, updater) {
    revoke({
          organizationMemberId: organizationMemberId
        }, undefined, undefined, (function (store, response) {
            var payload = makeRevokeInvitationPayload(response);
            Core__Option.forEach(updater, (function (updater) {
                    updater(store, payload);
                  }));
          }), (function (response, errors) {
            var payload = makeRevokeInvitationPayload(response);
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error revoking invitation", "Spaces__Invitation__Mutations handleRevoke", onError, (function () {
                    Core__Option.forEach(onSuccess, (function (onSuccess) {
                            onSuccess(payload);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(payload);
                          }));
                  }));
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error revoking invitation", "Spaces__Invitation__Mutations handleRevoke", onError);
          }), undefined);
  };
  return [
          handleRevoke,
          match[1]
        ];
}

var convertVariables$3 = SpacesInvitationMutations_inviteOrganizationMemberHumanMutation_graphql.Internal.convertVariables;

var convertResponse$3 = SpacesInvitationMutations_inviteOrganizationMemberHumanMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$3 = SpacesInvitationMutations_inviteOrganizationMemberHumanMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$3, SpacesInvitationMutations_inviteOrganizationMemberHumanMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

var use$3 = RescriptRelay_Mutation.useMutation(convertVariables$3, SpacesInvitationMutations_inviteOrganizationMemberHumanMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

function makeInviteOrganizationMemberPayload(response) {
  var organizationMember = response.inviteOrganizationMemberHuman.human;
  return {
          organizationMember: {
            id: organizationMember.id,
            name: organizationMember.name,
            surname: organizationMember.surname,
            email: organizationMember.email
          }
        };
}

function useInviteOrganizationMember() {
  var match = use$3();
  var invite = match[0];
  var handleInvite = function (email, organizationId, onError, onSuccess, onCompleted, updater, relayConnectionsToUpdateOpt) {
    var relayConnectionsToUpdate = relayConnectionsToUpdateOpt !== undefined ? relayConnectionsToUpdateOpt : [];
    var email$1 = email.trim();
    var match = Utils__Email.looksLikeEmail(email$1);
    if (match === "maybe" || match === "yes") {
      return invite({
                  email: email$1,
                  organizationId: organizationId,
                  relayConnectionsToUpdate: relayConnectionsToUpdate
                }, undefined, undefined, (function (store, response) {
                    var payload = makeInviteOrganizationMemberPayload(response);
                    Core__Option.forEach(updater, (function (updater) {
                            updater(store, payload);
                          }));
                  }), (function (response, errors) {
                    var payload = makeInviteOrganizationMemberPayload(response);
                    ErrorHandling__RelayMutationCompleted.handle(errors, "Error inviting member", "Spaces__Invitation__Mutations handleInvite", onError, (function () {
                            Core__Option.forEach(onSuccess, (function (onSuccess) {
                                    onSuccess(payload);
                                  }));
                          }), (function () {
                            Core__Option.forEach(onCompleted, (function (onCompleted) {
                                    onCompleted(payload);
                                  }));
                          }));
                  }), (function (errors) {
                    ErrorHandling__RelayMutationError.handle(errors, "Error inviting member", "Spaces__Invitation__Mutations handleInvite", onError);
                  }), undefined);
    } else {
      return Core__Option.forEach(onError, (function (onError) {
                    onError("Invalid email", "Email appears to be invalid. Please check and try again.");
                  }));
    }
  };
  return [
          handleInvite,
          match[1]
        ];
}

export {
  SharedPayload ,
  useAcceptInvitation ,
  useResendInvitation ,
  useRevokeInvitation ,
  useInviteOrganizationMember ,
}
/*  Not a pure module */
